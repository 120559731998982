.svg-icon {
	width: 1rem;
	height: 1rem;

	fill: currentColor;

	/*display: inline-block;*/
	/*box-sizing: border-box;*/

	text-align: center;
	vertical-align: center;
}
