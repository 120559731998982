table.metrics {
	width: 100%;
}

table.metrics td:nth-child(2) {
	text-align: end;
}

table.stats {
	width: 100%;
}

table.stats td:nth-child(2) {
	text-align: start;
}

table.stats td:nth-child(3) {
	text-align: end;
}

table.stats td:nth-child(4) {
	text-align: end;
}

table.records {
	width: 100%;
}

table.records td:nth-child(2) {
	text-align: end;
}
