.membership-status {
	color: #808080;
}

.owner {
	color: #c0c000;
	/*background-color: #004000;*/
	/*border-color: #00a000;*/
}

.accepted {
	color: #00e0a0;
	/*background-color: #004000;*/
	/*border-color: #00a000;*/
}

.invited {
	color: #00a0e0;
	/*background-color: #404040;*/
	/*border: 1px solid #a0a0a0;*/
}

.requested {
	color: #c0c000;
	/*background-color: #404040;*/
	/*border: 1px solid #a0a0a0;*/
}

.revoked {
	color: #e00000;
	/*background-color: #400000;*/
	/*border-color: #a00000;*/
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 6rem;
	background-color: var(--tooltip-background-1);
	text-align: center;
	padding: 0.2rem 0;
	border-radius: 0.3rem;

	/* Position the tooltip text */
	position: absolute;
	z-index: 1;

	top: -0.2rem;
	right: 200%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}

.tooltip .tooltiptext::after {
	content: " ";
	position: absolute;
	top: 50%;
	left: 100%; /* To the right of the tooltip */
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent transparent var(--tooltip-background-1);
}
