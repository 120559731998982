.nav-bar {
	margin-top: 1rem;
	display: flex;
	flex-flow: row nowrap;
	/* along the main axis */
	justify-content: center;
	/* along the cross axis */
	align-items: center;
}

.nav-icon {
	width: 2rem;
	height: 2rem;
}

.nav-button {
	font-size: 1.5rem;

	color: var(--foreground-1);
	background: var(--action-background-1);

	border-radius: 50%;
	border: 1px solid var(--border-1);

	margin: 2px;

	width: 3rem;
	height: 3rem;

	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.nav-button:hover {
	background: var(--action-background-2);
}
